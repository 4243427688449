import React from 'react';
import { ThemeProvider } from 'styled-components';
import { bhpTheme } from 'common/src/theme/bhp';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle } from '../containers/BHP/bhp.style';
import SEO from '../components/seo';

import ErrorSec from '../containers/Error';

const NotFoundPage = () => (
  <ThemeProvider theme={bhpTheme}>
    <SEO title="404: Not found" />
    <ResetCSS />
    <GlobalStyle />
    <ErrorSec></ErrorSec>
  </ThemeProvider>
);

export default NotFoundPage;
